import React from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { Email } from '@mui/icons-material';


const contactus = () => {
  return (
    <Grid container pt='5%' pb='2%' pl="10%" pr="10%" bgcolor="#fff" alignItems="center">
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Typography class='cont_h1' variant='h1'fontSize="3rem" fontWeight="500" color="#c53333">We like to hear from you!</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src="assets/contact1.png" alt="contact us" class="Contactimg" height="auto" width="500px"/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography className="note_" variant='h5' fontWeight="400"><br/><span style={{lineHeight:'42px'}}>If you are a Customer, Vendor or Driver. <br/>Please write to us on the email below.</span></Typography>
            <div className="email">
            <Email/><a href='mailto:contactus@meats4all.co.uk'>contactus@meats4all.co.uk</a>
            </div>
        </Grid>
    </Grid>
  )
}

export default contactus;