import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
// import { InputLabel } from '@mui/material';
// import { FormControl } from '@mui/material';
// import { InputBase } from '@mui/material';
// import { alpha, styled } from '@mui/material';
// import { useForm } from "react-hook-form";

// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

  const Joinus = () => {
    // const { register, handleSubmit, formState: { errors } } = useForm();
    // window.scrollTo(0,0)
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    let formData = new FormData();
    const [first_name, setFirst_name] = useState('')
    const [last_name, setLast_name] = useState('')
    const [select_user, setSelect_user] = useState('')
    const [email_address, setEmail_address] = useState('')
    const [phone_number, setPhone_number] = useState('')
    const [address, setAddress] = useState('')
    const sendEmail = () => {
      formData.append("first_name", first_name)
      formData.append("last_name", last_name)
      formData.append("select_user", select_user)
      formData.append("email_address", email_address)
      formData.append("phone_number", phone_number)
      formData.append("address", address)
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      fetch(`https://admin.meats4all.co.uk/user/join_us/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            console.log(response.message)
            setAddress('')
            setEmail_address('')
            setFirst_name('')
            setLast_name('')
            setPhone_number('')
            setSelect_user('')
           alert('Form Submitted Succesfully')
          }
        })
    }
   
  const checkValidation=(e)=>{
    e.preventDefault()
    let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(phone_number.match(phoneno))
    {
      sendEmail();
    }
    else{
      alert('please enter valid mobile number')
    }
  }

  return (<>

    <Grid container direction='row' className="Join_us_Main_container" alignItems='left' >
      <Grid item xs={12} sm={12} md={8} lg={12} className="Join_us_Main_sub_container" id="joinus">
        <Typography variant="h1" fontSize='48px' pb='15px' color='#e91f1f' fontWeight='bold' className='Want_to'>Want to Join Us ?<span style={{ color: "#a7a7a7", fontWeight: "500" }}> Start Here!</span></Typography>
        <Typography >Join Meats4all as a Vendor or Delivery Partner and connect with Meat Lovers across the region</Typography>
      </Grid>
    </Grid>
    <Grid container alignItems='left' className='Join_us_Main_container_2'>
      <Grid item xs={10} sm={10} md={4} lg={4} >
        <div className="wrapper">
          <div className="form">
            <form action="" onSubmit={checkValidation}>
              <div className="inputfield">
                <label>First Name</label>
                <input type="text" value={first_name} onChange={(e) => setFirst_name(e.target.value)} className="input" required />
              </div>
              <div className="inputfield">
                <label>Last Name</label>
                <input type="text" value={last_name} onChange={(e) => setLast_name(e.target.value)} className="input" required />
              </div>

              <div className="inputfield">
                <label style={{ margin: '0px' }}>Select User</label>
                <div className="custom_select">
                  <select className='custom_select' value={select_user} onChange={(e) => setSelect_user(e.target.value)} required>
                    <option value="">Select</option>
                    <option value="Vendor">Vendor Partner</option>
                    <option value="Driver">Driver Partner</option>
                  </select>
                </div>
              </div>

              <div className="inputfield">
                <label>Email Address</label>
                <input type="email" value={email_address} onChange={(e) => setEmail_address(e.target.value)} className="input" required />
              </div>
              <div className="inputfield">
                <label>Phone Number</label>
                {/* <PhoneInput
                country='us'
                value={phone_number}
                onChange={(e) => setPhone_number(e.target.value)}/> */}
                <input type='PhoneInput' value={phone_number} onChange={(e)=>setPhone_number(e.target.value)} className="input" required />
              </div>
              <div className="inputfield">
                <label>Address</label>
                <textarea className="textarea" value={address} onChange={(e) => setAddress(e.target.value)} required></textarea>
              </div>


              <div className="inputfield">
                <input type="submit" value="Join Now!" className="btn" name="submit" />
              </div>
            </form>
          </div>

        </div>



      </Grid>
      <Grid item xs={10} sm={10} md={8} lg={8} className="Box_image_container" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <img src='assets/joinus.png' height="466px" width="auto" />
      </Grid>
    </Grid>

    <Grid container className='Join_us_Main_container_3'>
      <Grid item xs={12} sm={12} md={6} lg={6} className="Art_container" >
        <img src='assets/Sliderart.png' className='Image' height="500px" width="auto" />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={5} className="Join_us_Sub_container_3_1" m="auto">
        <Typography variant="h2" fontSize='48px' pb='15px' color='#e91f1f' fontWeight='bold' >The Vendor Advantages</Typography>
        <Typography color="#ffffff">Meat vendors can add products they sell on the Meats4all platform, manage stock quantities,
          inventries, pricing, offer promotions to customers and manage daily, weekly and monthly sales views all using a simple, easy to use dashboard on the
          Meats4all Vendor App.</Typography>
      </Grid>

    </Grid>
  </>
  )
}

export default Joinus;


