import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Join_us from './components/Joinus';
import Aboutus from './components/About_Us';
import CreateYourMeatbox from './components/CreateYourMeatbox';
import Offers from './components/Offers';
import NavbarComp from './components/NavbarComp';
//import ResponsiveAppBar from './components/HeaderComp';
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import CustomerTermsAndConditions from "./components/CustomerTermsAndConditions";
// import PageNotFound from './components/PageNotFound';
import Privacy from './components/Privacy';
import Help from './components/help';
import Terms_Conditions from './components/Terms_Conditions';
import VendorTermsAndConditions from './components/VendorTermsAndConditions';
import Slider from './components/Slider';
import ContactUs from './components/ContactUs';

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <NavbarComp />
          <Routes>
            <Route index path="/" element={<Homepage />} />
            <Route path='/About_Us' element={<Aboutus />} />
            <Route path='/Join_us' element={<Join_us />} />
            <Route path='/offers' element={<Offers />} />
            <Route path='/NavbarComp' element={<NavbarComp />} />
            <Route path='/Create_Your_Meatbox' element={<CreateYourMeatbox />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path="/TermsConditions" element={<Terms_Conditions />} />
            <Route path="/CustomerTerms" element={<CustomerTermsAndConditions />} />
            <Route path="/VendorTerms" element={<VendorTermsAndConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path='/Slider' element={<Slider />} />
            <Route path="/help" element={<Help />} />
            {/* <Route path='*' element={<PageNotFound />} /> */}
            <Route path="*" element={<Navigate to ="/" />} />
          </Routes>
          <Footer />
        </Router>
      </>
    );
  }
}

export default App;
