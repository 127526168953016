import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import AppDownload from './AppDownload';

const CreateYourMeatbox = () => {

  const Maincontainer = {
    backgroundPosition: "right",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  }
  const [Category, setCategory] = useState('Email')
  return (<>
    <Grid container style={Maincontainer} className="MainContainer">
      <Grid item xs={12} sm={12} md={6} lg={7} pl="50px" pt="50px" className="sub_container">
        <Typography variant='h2' color="#e50019" fontWeight="600" pb="10px">Meats4all <span style={{ color: '#101100' }}>Meatbox</span></Typography>
        <Typography fontSize="1.3rem">With Meats4all meatbox, you can now choose different types of Meat and create a special meatbox for yourself, friends and your loved ones. On the Meats4all app, you can even choose to donate a meatbox to any charity among the list of registered charitable organisations.
        </Typography>

        {/* <Box className='App-container'>
        <Box pt='50px' className='Create_Box_1'>
          <Typography variant="h3" fontSize='32px' fontWeight='500' >Get The App Now!</Typography>
          <Typography py='10px'>Download and place your now.</Typography>
          <RadioGroup row value={Category} onChange={(e) => setCategory(e.target.value)} py='10px'>
            <FormControlLabel value="Email" control={<Radio color="error" />} label="Email" />
            <FormControlLabel value="Phone" control={<Radio color="error" />} label="Phone" />
          </RadioGroup>
          <TextField size='string' variant="outlined" placeholder="Email" required /><br /><br /><br />
          <Button variant="contained" color="error" size='large'>Share App Link</Button>
        </Box>

        <Box textAlign='center' className="Create_Box_2">
          <img src='assets/Customer-App-Mockup.png' className='App_Mockup_cymb' height="300px" width="450px" />
          <Typography fontWeight="500" color="#e50019">Download on</Typography><br/>
          <a href="https://apps.apple.com/in/app/meats4all/id1567429303"><img src="assets/Appstore.png" width='120px' height='auto' /></a> &nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US"><img src="assets/Googleplay.png" width='120px' height='auto' /></a>
        </Box>
        </Box> */}
        <img src="assets/6.png" alt="Meat on the pan" width="500px" height="auto" className='img_2_cymb' />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={5} className="Man_image_container">
        <img src='assets/meatbox1.png' alt="Delivery Partner with Meat Box" className='Man_Image' />
      </Grid>
    </Grid>

    <Box className='App-container-2'>

      <Box py='50px' className='Create_Box_1'>
        <Typography variant="h3" fontSize='32px' fontWeight='500' >Get The App Now!</Typography>
        <Typography py='10px'>Download and place your now.</Typography>
        <RadioGroup row value={Category} onChange={(e) => setCategory(e.target.value)} py='10px'>
          <FormControlLabel value="Email" control={<Radio color="error" />} label="Email" />
          <FormControlLabel value="Phone" control={<Radio color="error" />} label="Phone" />
        </RadioGroup>
        <TextField size='string' variant="outlined" placeholder="Email" required /><br /><br />
        <Button variant="contained" color="error" size='large'>Share App Link</Button>
      </Box>

      <Box textAlign='center' className="Create_Box_2">
        <img src='assets/Customer-App-Mockup.png' className='App_Mockup_cymb' height="250px" width="400px" />
        <Typography>or Download From </Typography>
        <a href="https://apps.apple.com/in/app/meats4all/id1567429303"><img src="assets/Appstore.png" width='120px' height='auto' /></a>
        <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US"><img src="assets/Googleplay.png" width='120px' height='auto' /></a>
      </Box>
    </Box>

    <Grid container className='image_container' justifyContent="center" alignItems="center" py="40px">
      <Grid item xs={10} sm={10} md={10} lg={12}>
        <Typography variant='h3' fontWeight='500' textAlign="center">Easy Steps to <span style={{ color: "#EA2024" }}>Create Your Meatbox</span></Typography>
      </Grid>
      <Grid item pt="30px">
        <img src='assets/Select-Vendor.png' alt="easy steps to create meatbox" className='img_1_cymb_mt' />
        <img src='assets/info-2.png' alt="easy steps to create meatbox" className='img_1_cymb_mtab' />
        <img src="assets/meatbox_grid.png" alt="easy steps to create meatbox" width="900px" height="300px" className='img_1_cymb' />
      </Grid>
      <Grid item >
        {/* <img src="assets/6.png" width="900px" height="auto" className='img_2_cymb' /> */}
      </Grid>
    </Grid>
    <AppDownload />
  </>)
}

export default CreateYourMeatbox;