import { useState } from 'react';
import { TextField, Button, Input } from "@mui/material";

const EmailRadio = () => {
    const [data, setData] = useState('')
    let bodyFormData = new FormData();
    const sendEmail = () => {
        bodyFormData.append('phone', '');
        bodyFormData.append('email', data);
        const requestOptions = {
            method: 'POST',
            body: bodyFormData
          };
        fetch(`https://admin.meats4all.co.uk/user/send_link/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
                 if(response.success){
                    //console.log(response)
                    alert('App link send on given email successfully')
                 }
                 else{
                    //  console.log(response)
                     alert('plese submit again')
                    }
            });
    }
    const handleinput = (e) => {
        e.preventDefault()
        setData(e.target.value)
    }
    const handleChange = () => {
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (data.match(mailformat)) {
              sendEmail();
              setData('');
          }
        else {
            alert("you have enter wrong email address")
        }
    }
    return (<>
        <TextField size='string' variant="outlined" placeholder={'Email'} value={data} onChange={handleinput} required className="text_field" /> &nbsp;
        <Button variant="contained" onClick={handleChange} color="error" size='large' height="55px">Share App</Button>
    </>)
}
export default EmailRadio;