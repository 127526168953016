import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import { useState, useEffect } from 'react';
import AppDownload from './AppDownload';
//import VenderOffer from './VendorOffer';
import VenderOffertest from './VenderOffertest';
// import MyVerticallyCenteredModal from './PopUp';


// const ShowPosts = () => {
//   const [posts, setPosts] = useState([]);

//   useEffect( () => { 
//       async function fetchData() {
//           try {
//               const res = await axios.get('http://138.68.155.215:8000/user/web_offer_section'); 
//               setPosts(res.data);
//           } catch (err) {
//               console.log(err);
//           }
//       }
//       fetchData();
//   }, []);
//   return <div>{posts}</div>
// }

//   const fetchoffers=async()=>{
//   const res = await axios.get('http://138.68.155.215:8000/user/web_offer_section')
//   return res
// }
  const Offers = () => {
  // const {data} = await useQuery('offers',fetchoffers)
  let formData = new FormData();
  const [posts, setPosts] = useState([]);
  const [vender,setVender] = useState([]);
  const [offer,setOffer] = useState(0);
  // const [modalShow, setModalShow] = useState(false);
  useEffect(() => {

    function fetchData() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      };
      fetch('https://admin.meats4all.co.uk/user/web_offer_section/', requestOptions)
      .then(response => response.json())
      .then((response) => {
        if (response.success) {
          let newarr = response.message;
          // let key = newarr.vendor_id;
          // let filterarray = [...new Map(newarr.map(item=>[item[key],item])).values()];
          // console.log(filterarray)
          // console.log(response.message)
          let filterarray = newarr.filter((a, i) => newarr.findIndex((s) => a.vendor_id=== s.vendor_id) === i)
          setPosts(filterarray)
          let venderarray = newarr.filter((item)=>item.vendor_id===49)
          // console.log(venderarray)
          setVender(venderarray)
        }
        else
        console.log(response.success)
      })
    }
    fetchData();
  }, []);
  function fetchselected(id) {
    formData.append('vendor_id',id)
    const requestOptions = {
      method: 'POST',
      body:formData
    }
    fetch('https://admin.meats4all.co.uk/user/web_offer_section/', requestOptions)
    .then(response => response.json())
    .then((response) => {
      if (response.success) {
        // console.log(response.message + "hi")
        setVender(response.message)
        // setModalShow(true)
      }
      else
      console.log(response.success+"err")
    })
  }
  function handleChange(e) {
    
  e.preventDefault()
  // console.log(offer)
    setOffer(e.target.value)
    // console.log(offer)
    // setOffer(e.target.value)
    // setVender(offer)
    // console.log(vender)  
   }
  const ExploreOffer=()=>{
    if(offer===0){
      alert('plese select a vender to see offer')
    }
    else{
      fetchselected(offer)
     
    }
  }

  return (<>
    <Grid container className='Offers_mainContainer_1'>
      <Grid item xs={12} sm={12} md={8} lg={6} className='Offers_subContainer_1'>
        <img src="assets/offers.png" height='300px' alt='' width='auto' />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={6} className='Offers_subContainer_2'>
        <Typography variant="h1" fontSize='36px' textAlign='left' pb='30px' color='#000000' fontWeight='300px' lineHeight='50px'><span style={{ color: "#ea1f23", fontWeight: "bold" }}>Special Discounts</span><br />from your Local Meat Providers</Typography>
        <Box>
          <FormControl style={{ display: 'flex', flexDirection: 'row' }} >
            <Select value={offer} onChange={handleChange}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ marginRight: '20px',width:'175px' }} >
              <MenuItem disabled value={0}><em>All Our Meat Vendors</em></MenuItem>
              {posts.map((data,index) => (
                // <MenuItem value={10}>The Meat & Fish Co.</MenuItem>
                // <MenuItem value={20}>Soley's Meat Centre</MenuItem>
                // <MenuItem value={30}>Umrah Super Market</MenuItem>
                // <MenuItem value={30}>Choice Super Market</MenuItem>
                <MenuItem key={index} value={data.vendor_id}>{data.vendor_shop_name}</MenuItem>
              ))}
            </Select>
            <Button variant="contained" color="error" size='large' style={{ textTransform: 'capitalize' }} onClick={ExploreOffer}>Explore Offers</Button>
               {/* <MyVerticallyCenteredModal show={modalShow} onHide={()=>setModalShow(false)}/> */}
          </FormControl>
        </Box>
      </Grid>
    </Grid>

    <Grid container className='SubContainer_22'>
      <Grid item xs={12} sm={12} md={12} lg={12} py='50px' className='Offers_subContainer_3'>
        <Typography variant="h1" fontSize='36px' textAlign='Center' pb='30px' color='#000000' fontWeight='300px'><span style={{ color: "#ea1f23", fontWeight: "600" }}>Current offers</span> from our Vendors</Typography>
      </Grid>
    </Grid>
<VenderOffertest vender={vender||[0]}/>
{/* <VenderOffer vender={vender||[0]}/> */}
    {/* <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-evenly" }}>
      <Grid item xs={12} sm={10} md={5} lg={5} className='items' style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", alignItems: "center", border: "0.5px solid #ed7474", borderRadius: "0px 24px 24px 0px" }}>
        <div>
          <img src="assets/offers1.png" height='150px' width='200px' />
        </div>
        
        <div style={{ paddingLeft: '10px' }}>
        {vender.map((value,index)=>
          <Typography fontSize="18px">{value.offer_name}  {value.offer_percentage} {value.offer_description}</Typography>
          )}
          <Typography fontSize="18px">Get 10% off on your first order</Typography>
          <Typography>   </Typography>
          <Button variant="contained" color="error" className='redeem_now'><a href="#H_Main_container_5">Redeem Now</a></Button>
        </div>
      </Grid>

      <Grid item xs={12} sm={10} md={5} lg={5} className="items" style={{ backgroundColor: "#fff", display: "flex", flexDirection: "row", alignItems: "center", border: "0.5px solid #ed7474", borderRadius: "0px 24px 24px 0px" }}>
        <div style={{ margin: '0px 0px' }}>
          <img src="assets/deliveryicon.png" height='150px' width='200px' />
        </div>
        <div style={{ paddingLeft: '10px' }}>
          <Typography fontSize="18px" pr="10px">Free delivery on orders over £50</Typography>
          <Typography>  </Typography>
          <Button variant="contained" color="error" className='redeem_now'><a href="#H_Main_container_5">Redeem Now</a></Button>
        </div>
      </Grid>
    </Grid> */}

    <AppDownload />


  </>)
}

export default Offers;