import React from 'react';
import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import EmailRadio from './EmailRadio';
import PhoneRadio from './PhoneRadio';


const AppDownload = () => {
    const [Category, setCategory] = useState('email');
    return (<>
        <Grid container className="H_Main_container_5" id="H_Main_container_5">
            <Grid item xs={10} sm={10} md={6} lg={6} className="H_Main_container_5_1">
                <img src="assets/Customer-App-Mockup.png" alt="Download the Meats4all App" height="450" width="auto" />
            </Grid>
            <Grid item xs={10} sm={10} md={6} lg={6} className="H_Main_container_5_2">
                <Typography variant="h3" fontSize='32px' fontWeight='500'>Get the app now!</Typography>
                <Typography py='10px' fontWeight="500" color="#e50019">Download and login to the app and bid goodbye to frozen Meat forever.</Typography>
                <a href="https://apps.apple.com/ee/app/meats4all-customer/id1567429303" target="_blank" rel="noreferrer" ><img src="assets/Appstore.png" alt="Meats4all App store" width='120px' height='auto' /></a>&nbsp;
                <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US" target="_blank" rel="noreferrer" > <img src="assets/Googleplay.png" alt="Meats4all Play store" width='120px' height='auto' /> </a><br /><br />
                <Typography variant="h3" fontSize='28px' fontWeight='500' lineHeight="1.0em">Share app with others</Typography>
                <Typography py='10px' fontWeight="500" color="#e50019">Share the app with your friends, family and loved ones.</Typography>

                <RadioGroup row value={Category} onChange={(e) => setCategory(e.target.value)} py='10px'>
                    <FormControlLabel value="email" control={<Radio color="error" />} label="Email" />
                    <FormControlLabel value="phone" control={<Radio color="error" />} label="Phone" />
                </RadioGroup> <br />
                {/* {newres === 'email' ? <Email /> : <Phone />} */}
                {/* <PhoneorEmail Radiobutt={category}/> */}
                {/* {PhoneorEmail ({Radiobutt:category})} */}
                {(Category==='email')?<EmailRadio/>:<PhoneRadio/>}
                {/* <TextField size='string' variant="outlined" placeholder={category} value={data} onChange={(e) => setData(e.target.value)} required className="text_field" /> &nbsp; */}
                {/* <Button variant="contained" onClick={handleChange} color="error" size='large' height="55px">Share App</Button><br /> <br /> */}
                <Typography py='10px' fontWeight="600" color="#e50019">Scan here to download</Typography>
                <img src="assets/scanqr.png" alt="scan to download" height='200px' />

            </Grid>
        </Grid>
    </>)
}

export default AppDownload