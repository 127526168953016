import React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CustomerTermsAndConditions from './CustomerTermsAndConditions';
import VendorTermsAndConditions from './VendorTermsAndConditions';
import { Link } from 'react-router-dom';

export const Terms_Conditions = () => {
    return (<>
        <Grid container spacing={2} justifyContent="center" py="10%">
            <Grid item xs={8} sm={8} md={4} lg={4} className="Terms_1">
                <Card sx={{ maxWidth: 380 }} className="card_1">
                    <CardActionArea>
                    <CardMedia
                            component="img"
                            image="assets/logo-33.png"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <Link to="/CustomerTerms">Customer Terms And Conditions</Link>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

            <Grid item xs={8} sm={8} md={4} lg={4} className="Terms_2">
                <Card sx={{ maxWidth: 380 }} className="card_2">
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image="assets/logo-2.png"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                <Link to="/VendorTerms">Vendor Terms And Conditions</Link>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

        </Grid>
    </>
    )
}
export default Terms_Conditions;
