import {TextField} from "@mui/material";
import {Button} from "@mui/material";
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { useState } from 'react';
const PhoneInAbout = ()=>{
    const [countrycode, setCountryCode] = useState(+44)
    const [data, setData] = useState('');
    const sendEmail = () => {
        let bodyFormData = new FormData();
        let phonenumber = `+${countrycode}${data}`
        bodyFormData.append('phone', phonenumber);
        bodyFormData.append('email', '');
        const requestOptions = {
            method: 'POST',
            body: bodyFormData
          };
        fetch(`https://admin.meats4all.co.uk/user/send_link/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
                 if(response.success){
                    // console.log(response)
                    alert('App link send on given mobile number successfully')
                 }
                 else{
                    //  console.log(response)
                     alert('plese submit again')
                    }
            });
    }
     const handleinput = (e) => {
        e.preventDefault()
        setData(e.target.value)
    }
    const handleCountry = (e) => {
        setCountryCode(e.target.value)
    }
    const handleChange = () => {
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (countrycode === '')
            return (alert("please select country code"))
        if (data.match(phoneno)) {
            setData('');
            sendEmail();
            // alert('App link send on Mobile')
        }
        else {
            alert("you have enter invalid mobile number")
        }
    }
    // console.log('in phone')
    return(<>
      <Select value={countrycode} displayEmpty onChange={handleCountry}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ marginRight: '20px', height: '50px' }} autoWidth>
            <MenuItem value={''}>Select Country</MenuItem>
            {/* <MenuItem value={+1}>+1</MenuItem> */}
            <MenuItem value={+44}>+44</MenuItem>
            <MenuItem value={+91}>+91</MenuItem>
            {/* <MenuItem value={+96}>SAUDIARAB</MenuItem> */}
        </Select>
    <TextField  value={data} size='string' variant="outlined" placeholder={'Phone'} onChange={handleinput} required /><br/><br/>
    <Button variant="contained" color="error" size='large' onClick={handleChange}>Order Now</Button>
    </>)
}
export default PhoneInAbout;