import React, { useState } from "react";
import { Grid, RadioGroup, Typography } from "@mui/material";
import { Box, FormControlLabel } from "@mui/material";
import {Radio} from "@mui/material";
import PhoneInAbout from "./PhoneInAbout";
import EmailInAbout from "./EmailInAbout";


function Aboutus() {
    const [Category, setCategory] = useState('email')
    return (
        <> 
            <Grid container direction='column' alignItems='center' className="About_Container_1" >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h1" fontWeight="bold" className="Heading_story">The Meats4all Story</Typography>
                    <Typography textAlign='center' margin='auto' color='#ffffff' className="description" fontSize='18px'>Meats4all is a one-stop application that connects Meat lovers and providers to buy and sell Meat seamlessly. We launched our app with the aim, that every family must have easy access to fresh and delicious Meat anytime throughout the year. Our digital platform connects you to your favourite local Meat vendors, so you never have to compromise quality of Meat for any occasion, big or small.</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={0} className='row-a'>
                <Grid item xs={12} sm={8} md={6} lg={6} px={4} className="col-1">
                    
                    <Typography variant="h2" className="Digitizing" fontSize='42px' pt='50px' color='#000000' fontWeight='regular' pb='15px'><span style={{ fontWeight: 'bold', color: '#E91F1F', letterSpacing:'2px' }}>Digitizing</span> The Local <br />Butcher Community</Typography>
                    
                    <Typography pb='10px' className="text1">For years, families have turned to their local butchers for fresh meats and their favourite cuts. At Meats4all, we realized that a lot of today’s digitization has forced families to adapt to new methods of buying, compromising on quality and what they would truly prefer.</Typography>
                    <Typography className="text1">Meats4all is an initiative to help local butcher shops turn digital and cater to the families who have been loyal customers. What’s more, butchers can offer promotions to sell better on the Meats4all platform effectively.</Typography>
                    
                    <Typography variant="h2" className="Join" fontSize='36px' pt='50px' color='#000000' fontWeight='regular' pb='15px'>Join The <span style={{ fontWeight: '500', color: '#E91F1F' }}>Meat Revolution</span></Typography>
                    <Typography>Download and login to the app and bid goodbye to frozen meat forever</Typography>
                    
                    <Box className='App-contain' style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                    <Box pt='50px' className="Box_1">
                        <Typography variant="h3" className="Get_App" fontSize='32px' fontWeight='500' >Get App Now!</Typography>
                        {/* <Typography py='10px'>Get <span style={{color:'red'}}>Meats4all Vendor App</span> Now</Typography> */}
                            <RadioGroup row value={Category} onChange={(e) => setCategory(e.target.value)} py='10px'>
                                <FormControlLabel value="email" control={<Radio color="error" />} label="Email" />
                                <FormControlLabel value="Phone" control={<Radio color="error" />} label="Phone" />
                            </RadioGroup>
                            {(Category==='email')?<EmailInAbout/>:<PhoneInAbout/>}
                        {/* <TextField  size='string' variant="outlined" placeholder={Category} required /><br/><br/>
                        <Button variant="contained" color="error" size='large'>Order Now</Button> */}
                   </Box>
                   <Box textAlign='center' className="Box_2" >
                    <img src='assets/Customer-App-Mockup.png' alt="Download the App" className="App_mockup" height="250px"/>
                    <Typography fontWeight="500" color="#e50019">Download on</Typography><br/>
                    <a href="https://apps.apple.com/ee/app/meats4all-customer/id1567429303" target="_blank" rel="noreferrer"> <img src="assets/Appstore.png" alt="Meats4all App store" width='120px' height='auto'/></a> &nbsp;
                    <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US" target="_blank" rel="noreferrer"> <img src="assets/Googleplay.png" alt="Meats4all Play store"  width='120px' height='auto'/></a> 
                   </Box>
                   </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={5} lg={5} className='col-2'>
                    <img src="/assets/aboutus.jpg" alt='meat' height='900px' width='100%' className="img-mt" />
                </Grid>
            </Grid>
        </>
    )


}

export default Aboutus;