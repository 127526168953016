import React from 'react';
import { Button, Grid, Typography} from '@mui/material';
import { Box } from '@mui/system';
import { Card, CardActionArea, CardMedia, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
// import ImageSlider from './ImageSlider';
import AppDownload from './AppDownload';
import Slider from './Slider';
import { useEffect } from 'react';

// borderBottom="2px solid #e50019"

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const Downloadicons = {
    backgroundColor: "#e50019",
    padding: "10px 0px 10px 0px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center"
  }
  // const slides = [
  //   { url: "assets/sliders/Image11.png", title: "Chicken Thighs" },
  //   { url: "assets/sliders/meatcut1.png", title: "Meat cut" },
  //   { url: "assets/sliders/Chicken_Thigh-min.png", title: "Chicken Thighs" },
  //   { url: "assets/sliders/salmon.png", title: "Salmon Fish" },
  //   { url: "assets/sliders/SheepChops.png", title: "SheepChops" },
  //   { url: "assets/sliders/WholeSheep.png", title: "Wholesheep" },
    
  // ];
  return (
    <>
      <Grid container  backgroundColor="#ffffff" className="H_Main_container_1">
        <Grid item xs={12} sm={12} md={6} lg={5} className="H_Main_container_1_s_1">
          <Typography  className='Text_1'>Delicious <span style={{ color: "#e50019" }}>Meat</span></Typography>
          <Typography  className='Text_2'>Delivered Fresh</Typography>
          <Typography className='Text_3'>&nbsp;Choice of your Meat packed and delivered to your doorstep</Typography> <br /><br />
          <Button variant='contained' color='error' py="20px"><a href="#H_Main_container_5">Order Now</a></Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5} className="H_Main_container_1_s_2">
          {/* <div className="containerStyles"> */}
            <Slider/> 
            {/* <ImageSlider slides={slides} /> */}
            {/* <span>{slides.title}</span> */}
          {/* </div> */}
        </Grid>
      </Grid>

      <Box style={Downloadicons} xs={12} sm={12} md={12} lg={10} className="H_Main_container_2">
        <Typography variant='h4' fontSize="24px" color="#f6f6f6" fontWeight="600">Download the App</Typography>
        <a href="https://apps.apple.com/in/app/meats4all/id1567429303" target="_blank" rel="noreferrer" ><img src='assets/Appst.png' alt="Meats4all App store" height="auto" width="70px" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US" target="_blank" rel="noreferrer" ><img src='assets/Playst.png' alt="Meats4all Play store" height="auto" width="54px" /></a>
      </Box>


      <Box textAlign="center" py="30px" className="H_Main_container_3">
        <Typography variant='h4' color="#101010" fontWeight="600">Order Your Meat - <span style={{ fontWeight: "400", color: "#e50019" }}>A few clicks away</span></Typography>
      </Box>

      <Grid container alignItems="center" justifyContent="center" className="H_Main_container_4">
        <Grid item xs={10} sm={10} md={4} lg={6}>
          <img src="assets/infograph.png" alt="infograph" className='mobile_infograph' />
          <img src='assets/Group12.png' alt="infograph" width="600px" height="auto" className="desktop_infograph" />
        </Grid>
        <Grid item xs={10} sm={10} md={4} lg={6}>
          <video width="600" height="400" controls className='video_h' poster="assets/videos/Video_Thumbnail.png">
            <source src='assets/videos/M4AVideo.mp4' type="video/mp4" />
          </video>
        </Grid>
      </Grid>

      {/* <Grid container className="H_Main_container_5">
        <Grid item xs={10} sm={10} md={6} lg={6} className="H_Main_container_5_1">
          <img src="assets/Customer-App-Mockup.png" height="450" width="auto" />
        </Grid>
        <Grid item xs={10} sm={10} md={6} lg={6} className="H_Main_container_5_2">
          <Typography variant="h3" fontSize='32px' fontWeight='500'>Get the app now!</Typography>
          <Typography py='10px' fontWeight="500" color="#e50019">Download and login to the app and bid goodbye to frozen Meat forever.</Typography>
          <a href="https://apps.apple.com/ee/app/meats4all-vendor/id1567430708"><img src="assets/Appstore.png" width='120px' height='auto' /></a>&nbsp;
          <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.vendormeat4all&hl=en_IN&gl=US"> <img src="assets/Googleplay.png" width='120px' height='auto' /> </a><br/><br/>
          <Typography variant="h3" fontSize='28px' fontWeight='500' lineHeight="1.0em">Share app with others</Typography>
          <Typography py='10px' fontWeight="500" color="#e50019">Share the app with your friends, family and loved ones.</Typography>
          <RadioGroup row value={Category} onChange={handleChange} py='10px'>
            <FormControlLabel value="Email" control={<Radio color="error" />} label="Email" />
            <FormControlLabel value="Phone" control={<Radio color="error" />} label="Phone" />
          </RadioGroup><br/>
          <TextField size='string' variant="outlined" placeholder="Email" required className="text_field" /> &nbsp;
          <Button variant="contained" color="error" size='large' height="55px">Share App</Button><br /> <br />
          <Typography py='10px' fontWeight="600" color="#e50019">Scan me to download</Typography>
          <img src="assets/scanqr.png" height='200px'/>
          
        </Grid>
      </Grid> */}
      <AppDownload/>

      <Grid container className="H_Main_container_6">
        <Grid item xs={10} sm={10} md={6} lg={6} className="H_Main_container_6_1">
          <Typography variant="h3" fontSize="44px" fontWeight="700" color="#e50019">Quality Meats <span style={{ fontWeight: "400", color: "#101010" }}>for everyone</span></Typography>
          <Typography>A perfect family meal requires the best ingredients and fresh Meat. Order from
            your favourite Meat provider on Meats4all and get delicious Meats delivered right
            to your doorstep at your convenient time.</Typography><br />
          <Typography fontSize="32px" fontWeight="500">No Storing. No Thawing. <span style={{ color: "#e50019" }}>No Hassles.</span>
          </Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={6} lg={5} className="H_Main_container_6_2">
          <img src="assets/image13.png" alt="Lamb Chops" />
        </Grid>
      </Grid>

      {/* cards */}
      <Grid container spacing={0} justifyContent="space-evenly" alignItems="stretch" className="H_Main_container_7">
        <Grid item xs={10} sm={7} md={4} lg={3} my="10px">
          <Card sx={{ maxWidth: 385, height: "100%" }} >
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image="assets/customer1.png"
                alt="Customers"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div" color="#E91F1F" fontWeight="600">
                  Fresh Meat at Your Doorstep
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="justify">
                  We understand what it means to have the right kind of Meat in your kitchen to make the perfect meal for your family. That is why we created a platform that can bring you closer to your favourite butcher with Meats4all.
                </Typography><br /><br /><br /><br />
                <Typography variant="body2" color="#101010" fontWeight="600" textAlign="center">Join other happy customers and start <span className='special'>Ordering Now!</span></Typography>
                <br /><br /><br />
              </CardContent>
            </CardActionArea>
            <CardActions>
          <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US"  id="button" style={{ margin: "auto" }}>
              <Button size="small" variant="contained" color='error'>
               Get The App
              </Button>
           </a>
            </CardActions>

          </Card>
        </Grid>

        <Grid item xs={10} sm={7} md={4} lg={3} my="10px">
          <Card sx={{ maxWidth: 385, height: "100%" }} >
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image="assets/vendor1.png"
                alt="Vendor Partner"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div" color="#E91F1F" fontWeight="600">
                  Empowering Local Meat Providers
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="justify">
                  At Meats4all, we believe that every local Meat provider deserves a platform to be able to offer their products. We believe that these local butchers need the power of digitization to connect them to Meat lovers around their community. And that's why Meats4all is a unique platform that bridges the gap between Meat aficionados and local butchers who carefully curate their products.
                </Typography><br /><br />
                <Typography variant="body2" color="#101010" fontWeight="600" textAlign="center">Be a part of the <span className='special'>Meat Revolution</span></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
            <Link to="Join_us" style={{ margin:"auto"}}> 
              <Button size="small" variant="contained" color='error' style={{ margin: "0px auto 0px auto" }} >
                Join Us
              </Button>
               </Link> 
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={10} sm={7} md={4} lg={3} my="10px">
          <Card sx={{ maxWidth: 385, height: "100%" }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="240"
                image="assets/driver1.png"
                alt="Driver Partner"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div" color="#E91F1F" fontWeight="600">
                  Connecting the Meat Lover Community
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="justify">
                  Connections are a human thing. In today's age, people are connected in very interesting ways. People to goods. People to services. People to food. That is why our Delivery Partners are always on their toes, ensuring this chain of connectivity is never broken.
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign="justify">So whenever you're ready to dish out that scrumptious, comforting chicken, tender lamb or fresh seafood meal. Our Delivery Partners are right around the corner to get you the best choice of Meat via Meats4all platform.</Typography>
                <br />
                <Typography variant="body2" color="#101010" fontWeight="600" textAlign="center">Are you a driver looking to <span className='special'>Join Us?</span></Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>  
              <Link to="Join_us" style={{ margin: "auto" }}>
              <Button size="small" variant="contained" color='error' style={{ margin: "auto" }} >
              Join Us
              </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>

      </Grid>
    </>
  )
}

export default Homepage;