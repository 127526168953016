import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles1.css';
import { useState } from 'react';


const NavbarComp = () => {
    const [Toggle, setToggle] = useState(false);
    // to change burger classes
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    // toggle burger menu change
    const updateMenu = () => {
        if (!isMenuClicked) { 
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")   
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    }


    return (<>
        <header className='Container_'>
            <div className='sub-container-1'>
                <img src='assets/M4a_logo.png' height="auto" alt="Meats4all" width="35%" />
            </div>
            <nav className='sub-container-2'>
                <ul>
                    <li className='Menu_items'> <NavLink to="/">Home</NavLink></li>
                    <li className='Menu_items'> <NavLink to="/about_us">About Us</NavLink></li>
                    <li className='Menu_items'> <NavLink to="/Create_Your_Meatbox">Create Your Meatbox</NavLink></li>
                    <li className='Menu_items'> <NavLink to="/Offers">Offers</NavLink></li>
                    <li className='Menu_items'> <NavLink to="/join_us">Join Us</NavLink></li>
                    <li className='Menu_items'> <NavLink to="/contactus">Contact Us</NavLink></li>
                </ul>
                {Toggle ? <ul className='mobile_list'>
                    <li> <NavLink to="/">Home</NavLink></li>
                    <li> <NavLink to="/about_us">About Us</NavLink></li>
                    <li> <NavLink to="/Create_Your_Meatbox">Create Your Meatbox</NavLink></li>
                    <li> <NavLink to="/Offers">Offers</NavLink></li>
                    <li> <NavLink to="/join_us">Join Us</NavLink></li>
                    <li> <NavLink to="/contactus">Contact Us</NavLink></li>
                </ul> : null}
            </nav>
            <button className='hamburger' onClick={() => setToggle(!Toggle)}>
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                </div>
            </button>
            {/* <button onClick={()=> setToggle(!Toggle)}><MenuIcon /></button>            */}

        </header>
    </>
    )
}

export default NavbarComp;