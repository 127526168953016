import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

class Footer extends Component {
   render() {
      return (<>
         <Grid container spacing={3} columnSpacing={1} bgcolor='#F5F5F5' my={2} alignItems='baseline'>
            <Grid item xs={12} sm={6} md={3} textAlign='center'>
               <p className="footer-headings"><span>Quick Links</span></p>
               <ul className="ul_list">
               <Link to="/help">Help</Link> <br/>
                <Link to="/privacy">Privacy Policy</Link><br/>
                <Link to="/TermsConditions">Terms & Conditions</Link>
               </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
               <p className="footer-headings"><span>Secured Payments</span></p>
               <img src="payments.png" height='30px' width='250px' alt="Secured Payments" className="p-image"></img>
            </Grid>
            <Grid item xs={12} sm={6} md={3} textAlign="center">
               <p className="footer-headings"><span>Downloads</span></p>
               <a href="https://apps.apple.com/in/app/meats4all/id1567429303" target="_blank" rel="noreferrer" ><img src='assets/Appstore.png' alt="Meats4all App store" height='36px' width='120px' /></a><br />
               <a href="https://play.google.com/store/apps/details?id=com.mkaglobal.meats4all&hl=en_IN&gl=US" target="_blank" rel="noreferrer" ><img src='assets/Googleplay.png' alt="Meats4all Play store" height='36px' width='120px'></img></a><br />
               <a href="https://www.meats4all.co.uk/M4a_Vendor_app_19.apk" target="_blank" rel="noreferrer" ><img src='assets/apk-button.png' alt="Meats4all Play store" height='36px' width='120px'></img></a>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
               <p className="footer-headings"><span>Contact us</span></p>
               <Box className="info_container" style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center', alignItems: 'flexStart' }}>
                  <Typography textAlign="center" fontSize="14px" fontWeight="500">Please email us on</Typography>
                  <Typography  fontSize="14px"><a href="mailto:contactus@meats4all.co.uk">Contactus@meats4all.co.uk</a></Typography>
               </Box>
            </Grid>
         </Grid>

         <Grid container bgcolor='#101010' padding="16px 40px" alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={6} xl={6} className="social_icons">
               <span style={{color:"#ffffff"}}>Follow us on</span>
            
               <a href="https://www.facebook.com/Meats4All-109484181306113/" target="_blank" rel="noreferrer" > <img src="assets/facebook.png" alt="Meats4all Facebook" /> </a>
               <a href="https://www.instagram.com/meats4all/" target="_blank" rel="noreferrer" ><img src="assets/instagram.png" alt="Meats4all Instagram" /></a>
               <a href="https://twitter.com/meats4all" target="_blank" rel="noreferrer" > <img src="assets/twitter.png" alt="Meats4all Twitter" /></a>
               <a href="https://www.linkedin.com/company/meats4all/" target="_blank" rel="noreferrer" > <img src="assets/linkedin.png" alt="Meats4all Linkedin" /></a>
               <a href="https://www.youtube.com/channel/UCXGH9XjXSl-7JTykJlY3-Bg" target="_blank" rel="noreferrer" > <img src="assets/youtube.png" alt="Meats4all Youtube" /></a>

            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
               <Typography className="copyright"> &copy; Copyright 2022 Meats4all All Rights Reserved.</Typography>
            </Grid>
         </Grid>
      </>
      )
   }
}

export default Footer;