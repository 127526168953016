import React from 'react';
import Carousel from 'better-react-carousel';
import { Grid } from '@mui/material';

const Slider = () => {
  return (<>
  {/* <Grid container>
  <Grid item xs={12} sm={12} md={6} lg={5} className="H_Main_container_1_s_2"> */}
    <Carousel cols={1} rows={1} gap={40} loop={true} hideArrow={true} autoplay={2000} showDots={true}>
    <Carousel.Item>
        <img width="100%" alt="Lamb" src="assets/sliders/1.png" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" alt="Meats4all delivered Order" src="assets/sliders/2.png" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" alt="Chicken Thighs" src="assets/sliders/3.png" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" alt="Fish" src="assets/sliders/4.png" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" alt="Sheep Chops" src="assets/sliders/5.png" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" alt="Whole Lamb" src="assets/sliders/6.png" />
      </Carousel.Item>
     
    </Carousel>
    {/* </Grid>
    </Grid> */}
    </>
  )
}

export default Slider;