import { useState } from "react";
import {TextField} from "@mui/material";
import {Button} from "@mui/material";;
const EmailInAbout = ()=>{
    const [data, setData] = useState('')
    let bodyFormData = new FormData();
    const sendEmail = () => {
        bodyFormData.append('phone', '');
        bodyFormData.append('email', data);
        const requestOptions = {
            method: 'POST',
            body: bodyFormData
          };
        fetch(`https://admin.meats4all.co.uk/user/send_link/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
                 if(response.success){
                    // console.log(response)
                    alert('App link send on given email successfully')
                 }
                 else{
                    //  console.log(response)
                     alert('plese submit again')
                    }
            });
    }
    const handleinput = (e) => {
        e.preventDefault()
        setData(e.target.value)
    }
    const handleChange = () => {
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (data.match(mailformat)) {
            setData('');
              sendEmail();
          }
        else {
            alert("you have enter wrong email address")
        }
    }
    return(<>
    <TextField  size='string' value={data} variant="outlined" placeholder={'Email'} onChange={handleinput} required /><br/><br/>
    <Button variant="contained" color="error" size='large' onClick={handleChange}>Order Now</Button>
    </>)
}
export default EmailInAbout;