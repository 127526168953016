import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import Popup1 from './Popup1';

const VenderOffertest = ({ vender }) => {
  const [showpopups, Setshowpopups] = useState(false)
  return (<>

    {/* <div  className='vender' style={{display:'flex',alignItems:'center',flexDirection:'row',overflowX:'scroll'}}> */}
    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-evenly", overflowX: 'scroll' }}>
      {vender.map((value, index) =>

        //  <Grid container style={{ display: 'inline', flexDirection: 'row', justifyContent: "space-evenly" }}>
        <Grid key={index} item xs={12} sm={10} md={5} lg={5} className='items' style={{ backgroundColor: "#fff", display: "flex", textAlign: 'center', flexDirection: "row", alignItems: "center", border: "0.5px solid #ed7474", borderRadius: "0px 24px 24px 0px", marginRight: '10px', marginLeft: '30px', padding: '10px 20px' }}>
          <div>
            {/* <img src="assets/offers1.png" height='150px' width='200px' /> */}
            <img src={value.vendor_image} height='auto' width='150px' style={{backgroundColor:"#000"}}/> 
            
          </div>

          <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            {/* {vender.map((value,index)=>
          <Typography fontSize="18px">{value.offer_name}  {value.offer_percentage} {value.offer_description}</Typography>
          )} */}
            <Typography fontSize="18px" fontWeight="600">{value.offer_description}</Typography>
            <Typography>   </Typography><br/>
            <Button variant="contained" color="error" className='redeem_now' onClick={() => Setshowpopups(true)} >Redeem Now</Button>
            <Popup1 trigger={showpopups} setTrigger={Setshowpopups}>
              <div>
                <h1>{value.vendor_shop_name}</h1>
                <br />
                <h3>{value.offer_name}</h3>
                <br />
                <h3 style={{color:"red"}}>{value.offer_code}</h3>
                <br />
                <h3>{value.offer_description}</h3>
                <br />
                <br />

              </div>
            </Popup1>
          </div>
        </Grid>
        // {/* </Grid> */}
      )}
    </Grid>
    {/* </div> */}

  </>)
}
export default VenderOffertest;