import React, {useState} from 'react';
import { Grid } from '@mui/material';
// import { Document, Page,pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CustomerTermsAndConditions() {
  
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages); 
      setPageNumber(1); 
    }
    function changePage(offSet) {
      setPageNumber(prevPageNumber => prevPageNumber + offSet);
    }

    function ChangePageBack(){
      changePage(-1)
    }
    function ChangePageNext() {
      changePage(+1)
    }
  return (<><div className='pages_container'>
    <Grid container justifyContent="center" alignItems='center' pt="10px" ml="auto" mr="auto" bgcolor="#ffffff">
      {/* <Grid item>
    <Document file="Meats4allCustomerTermsAndConditions.pdf" onLoadSuccess={onDocumentLoadSuccess}>   
      <Page height="600" pageNumber={pageNumber} />
    </Document>
    <p>
      Page {pageNumber} of {numPages}
    </p>

  {pageNumber > 1 &&
  <button onClick={ChangePageBack}>Previous Page</button>
  }
{
  pageNumber < numPages &&
  <button onClick={ChangePageNext} >Next Page</button>
}
    </Grid> */}
    <Grid item  xs={10} sm={10} md={10} lg={12}>

    </Grid>
  </Grid>
  <Document file="Meats4allCustomerTermsAndConditions.pdf" onLoadSuccess={ onDocumentLoadSuccess } >
      { 
      Array.from(new Array(numPages),(el,index) => (
      <Page className="pages" key={'page_${index+1}'} pageNumber = {index+1} /> ) ) 
      }
    </Document>
    </div>
  </>);
}

export default CustomerTermsAndConditions;