import { useState } from 'react';
import { TextField, Button } from "@mui/material";
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
const PhoneRadio = () => {
    const [data, setData] = useState('')
    const [countrycode, setCountryCode] = useState(+44)
    let bodyFormData = new FormData();

    const sendEmail = () => {
        let phonenumber = `+${countrycode}${data}`
        bodyFormData.append('phone', phonenumber);
        bodyFormData.append('email', '');
        const requestOptions = {
            method: 'POST',
            body: bodyFormData
          };
        fetch(`https://admin.meats4all.co.uk/user/send_link/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
                 if(response.success){
                    // console.log(response)
                    alert('App link send on given mobile number successfully')
                 }
                 else{
                    //  console.log(response)
                     alert('plese submit again')
                    }
            });
    }
    const handleinput = (e) => {
        e.preventDefault()
        setData(e.target.value)
    }
    const handleCountry = (e) => {
        setCountryCode(e.target.value)
    }
    const handleChange = () => {
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (countrycode === '')
            return (alert("please select country code"))
        if (data.match(phoneno)) {
            setData('');
            sendEmail();
            // alert('App link send on Mobile')
        }
        else {
            alert("you have enter invalid mobile number")
        }
    }

    // const [countrycode,setCountryCode] = useState('')
    return (<>
        <Select value={countrycode} displayEmpty onChange={handleCountry}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ marginRight: '20px', height: '45px' }} autoWidth>
            <MenuItem value={''}>Select Country</MenuItem>
            {/* <MenuItem value={+1}>+1</MenuItem> */}
            <MenuItem value={+44}>+44</MenuItem>
            {/* <MenuItem value={+91}>+91</MenuItem> */}
            {/* <MenuItem value={+96}>SAUDIARAB</MenuItem> */}
        </Select>
        <TextField size='string' variant="outlined" placeholder={'Phone'} value={data} onChange={handleinput} required className="text_field" /> &nbsp;
        <Button variant="contained" onClick={handleChange} color="error" size='large' height="55px">Share App</Button>
    </>
        // <Button variant="contained" onClick={handleChange} color="error" size='large' height="55px">Share App</Button>
    )
}
export default PhoneRadio;